<template>
  <MainLayout>
    <div>
      <TableFilters :queries="queries">
        <template v-slot:filter-title>
          <span v-if="sourceObject.count">
            <span class="title-filter">Всего:</span>{{ sourceObject.count }}
            <!--          <span>групп пользователей</span>-->
          </span>
        </template>
        <template v-slot:download-button>
          <v-btn
            @click="downloadList"
            class="btn btn_color_grey btn-unload flex-right mr-4"
            :disabled="loadingBtn || sourceObject.results.length == 0"
            :loading="loadingBtn"
          >
            Выгрузить
          </v-btn>
        </template>
        <template
          v-if="currentRole == 70 || currentRole == 31"
          v-slot:add-button
        >
          <router-link
            to="/childrenGroupsCreate"
            append
            class="btn btn-add btn_color_green"
            >Добавить детскую группу</router-link
          >
        </template>
      </TableFilters>
      <TableLayout
        :loading="isDateLoaded"
        :header="header"
        :source-object="sourceObject"
        :table-data="sourceObject.results"
      />
    </div>
  </MainLayout>
</template>

<script>
import TableFilters from "@/components/tables/TableFilters";
import TableLayout from "@/components/tables/TableLayout";
import MainLayout from "@/components/layouts/MainLayout";
import * as query from "@/helpers/query";
// import tableColsChildrenGroups from "@/helpers/tableCols/childrenGroups";
import { dictionariesHelper } from "@/helpers/dictionariesHelper";
export default {
  name: "ChildrenGroups",
  components: { MainLayout, TableLayout, TableFilters },
  data() {
    return {
      loadingBtn: false,
      header: [
        {
          text: "Номер детской группы",
          value: "number",
          sort: true
        },
        {
          text: "Название детской группы",
          value: "name",
          sort: true
        },
        {
          text: "Группы пользователей",
          value: "group",
          renderFunction: value =>
            value.group_details ? value.group_details.title : "",
          sort: true
        },
        {
          text: "Воспитатели",
          renderFunction: value =>
            value?.teachers_details
              ?.map(
                el =>
                  (el.additional_fields.length != 0
                    ? el.additional_fields[0].value
                    : "-") +
                  " (" +
                  `${
                    dictionariesHelper.accountsStatus.userAuth[
                      el.is_phone_confirmed
                    ]
                  }` +
                  ")" +
                  "<br/>"
              )
              ?.join("\n")
        }
      ]
    };
  },
  computed: {
    queries() {
      return query.groupChildren.filter(el =>
        el.userRole.includes(this.currentRole)
      );
    },
    currentRole() {
      return this.$store.getters.getCurrentRole;
    },
    currentUserId() {
      return this.$store.getters.getUserId;
    },
    // header() {
    //   return tableColsChildrenGroups;
    // },
    urlQuery() {
      return {
        query: this.$route.query,
        id: this.$route.params.id
      };
    },
    sourceObject() {
      return this.$store.getters.getChildrenGroups;
    },
    tableData() {
      if (this.currentRole == 21) {
        const arrWithCurrentTeacher = this.$store.getters.getChildrenGroups.results.filter(
          el => el.teachers.includes(this.currentUserId)
        );
        return arrWithCurrentTeacher;
      } else {
        return this.$store.getters.getChildrenGroups.results;
      }
    }
  },
  methods: {
    downloadList() {
      this.loadingBtn = true;
      this.$store
        .dispatch("downloadChildrenGroupsList", this.header)
        .then(() => {
          this.loadingBtn = false;
        });
    }
  },
  created() {
    this.$store.dispatch("clearSortQuery");
    this.$store.commit("setSearchQuery", {
      query: {
        ...this.urlQuery.query,
        kindergarten_org: this.$store.getters.getCurrentOrganization
      }
    });
    this.isDateLoaded = true;
    this.$store.dispatch("fetchKinderInfo");
    this.$store.dispatch("fetchChildrenGroupByKindergartens").finally(() => {
      this.isDateLoaded = false;
    });
  },
  beforeRouteUpdate(to, from, next) {
    if (to.path === from.path) {
      this.$store.commit("setSearchQuery", {
        query: {
          ...to.query,
          kindergarten_org: this.$store.getters.getCurrentOrganization
          // type: this.type
        }
      });
      this.isDateLoaded = true;

      this.$store.dispatch("fetchChildrenGroupByIdList").finally(() => {
        this.isDateLoaded = false;
      });
    }
    next();
  }
};
</script>

<style scoped></style>
